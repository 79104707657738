.bg_login{
    width: 100%;
    height: 100vh;
    background-image: url('../../Assets/18220872_v1016-a-02.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.form_login {
    padding: 40px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border-radius: 9px;
    background-color: #fff;
    width: 42%;
    margin: auto;
}

@media (max-width: 900px){
    .form_login{
        width: 60% !important;
    }
}

@media (max-width: 620px){
    .form_login{
        width: 70% !important;
    }
}

@media (max-width: 500px){
    .form_login{
        width: auto !important;
    }
}