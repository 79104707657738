.categorys_dashboard {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
}

.graph_card {
    border-radius: 5px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
}

.graph_card_top {
    background-color: #6C7E97;
    border-bottom: 1px solid #6C7E97;
    border-radius: 5px 5px 0 0;
    padding: 8px;
}

.flex_container {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}

.accord_details {
    max-height: 450px;
    overflow-y: auto;
}

/* .accord_details::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}


.accord_details::-webkit-scrollbar-thumb {
    background-color: #D8D8D8 !important;
}

.accord_details::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #FFFFFF;
} */
.accord_details::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
}

.accord_details::-webkit-scrollbar-track {
    background-color: #D8D8D8;
}

.accord_details::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

@media (max-width: 500px) {
    .categorys_dashboard {
        box-shadow: none;
        border: none;
        border-radius: 0%;
        padding: 0;
    }
}

.filters_mobile {
    display: none;
    padding: 10px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
}

@media (max-width: 991.5px) {
    .filters_desktop {
        display: none;
    }

    .filters_mobile {
        display: flex;
        justify-content: flex-end;
    }
}